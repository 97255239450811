import { h } from 'preact';
import { useState } from'preact/hooks';
import style from './app.module.scss';
import Input from './input/Input';


enum TriState {
	YES,
	NO,
	MAYBE,
}

function calculate(declared, targetted, controls, graveyard): TriState {
	if (declared === "" || targetted === "" || controls === "" || graveyard === "") {
		return TriState.MAYBE;
	}

	const declaredInt = parseInt(declared);
	const targettedInt = parseInt(targetted);
	const controlsInt = parseInt(controls);
	const graveyardInt = parseInt(graveyard);

	const result = declaredInt * targettedInt + controlsInt;

	console.log([
		declaredInt,
		targettedInt,
		controlsInt,
		graveyardInt,
		result
	]);

	return result === graveyardInt ? TriState.YES : TriState.NO;
}

const App = () => {
	const [declared, setDeclared]   = useState("");
	const [targetted, setTargetted] = useState("");
	const [controls, setControls]   = useState("");
	const [graveyard, setGraveyard] = useState("");

	const state = calculate(declared, targetted, controls, graveyard);

	return (
		<div id="app" class={style.app}>
			<div class={style.questions}>
				<Input state={[declared, setDeclared]}   before="I declared" />
				<Input state={[targetted, setTargetted]} before="I targetted a level" after="monster" />
				<Input state={[controls, setControls]}   before="My opponent controls" after="cards" />
				<Input state={[graveyard, setGraveyard]} before="I have" after="cards in the GY" />

				<div class={style.question}>
					How do I resolve <br />Linear Equation Cannon?
				</div>
			</div>

			{state === TriState.MAYBE && <div class={[style.answers, style.maybe].join(" ")}>
				<div class={style.maybe}>
					?
				</div>
			</div>}

			{state === TriState.YES && <div class={[style.answers, style.yes].join(" ")}>
				<div class={style.yes}>
					YES
				</div>
			</div>}

			{state === TriState.NO && <div class={[style.answers, style.no].join(" ")}>
				<div class={style.no}>
					NO
				</div>
			</div>}
			
		</div>
	);
}

export default App;
