import { h } from 'preact';
import { StateUpdater, useCallback, useRef, useState } from 'preact/hooks';
import style from './Input.module.scss';

interface InputProps {
    before?: string,
    after?: string,
    state: [string, StateUpdater<string>]
}

const Input = ({
    before,
    after,
    state: [value, setValue]
}: InputProps) => {
    
    const ref= useRef(null);

    const onChange = useCallback((e) => {
        setValue(e.target.value.replaceAll(/\D/g, ""));
        ref && (ref.current.value = e.target.value.replaceAll(/\D/g, ""));
    }, [setValue]);

    return (
        <div class={style.input}>
            {before}
            <input class={style.field} type="text" onInput={onChange} ref={ref} />
            {after}
        </div>
    );
}

export default Input;
